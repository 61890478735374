
import React from 'react';
import { Carousel } from 'react-carousel-minimal';


const CarouselComponent = ({ data }) => {
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };

  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };

  return (
    <div style={{ textAlign: 'center' }}>
      <div style={{ padding: '0 20px' }}>
        <Carousel
          
          data={data}
          time={5000}
          width="850px"
          height="700px"
          captionStyle={captionStyle}
          radius="10px"
          slideNumber={true}
          slideNumberStyle={slideNumberStyle}
          captionPosition="bottom"
          automatic={true}
          dots={true}
          pauseIconColor="white"
          pauseIconSize="40px"
          slideBackgroundColor="darkgrey"
          slideImageFit="contain"
          thumbnails={true}
          thumbnailWidth="100px"
          style={{
            textAlign: 'center',
            maxWidth: '1000px',
            maxHeight: '900px',
            margin: '40px auto',
            display:"center"
          }}
          video={true}
        />
      </div>
    </div>
  );
};

export default CarouselComponent;