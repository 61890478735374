import React from 'react';
import './Footer.css';
import { Button } from './Button';
import { Link } from 'react-router-dom';

function Footer() {
  return (
    <div className='footer-container'>
      <section className='footer-subscription'>
        <p className='footer-subscription-heading'>
          Abonează-te acum la newsletter-ul nostru ca să afli noile tendinte în materie de calculatoare!
        </p>
        <p className='footer-subscription-text'>
          Te poți dezabona oricand
        </p>
        <div className='input-areas'>
          <form>
            <input
              className='footer-input'
              name='email'
              type='email'
              placeholder='E-mail-ul tau'
            />
            <Button buttonStyle='btn--outline'>Abonare</Button>
          </form>
        </div>
      </section>
      <div class='footer-links'>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Despre noi</h2>
            <Link to='/despre-noi'>Cine suntem?</Link>
            <Link to='/galerie'>Galerie</Link>
            <Link to='/'>Termeni si conditii</Link>
          </div>
          <div class='footer-link-items'>
            <h2>Suport</h2>
            <Link to='/'>Contact</Link>
          </div>
        </div>
        <div className='footer-link-wrapper'>
          <div class='footer-link-items'>
            <h2>Social Media</h2>
            <Link to='https://www.instagram.com/ratsystem_iasi/' target='_blank'>Instagram</Link>
            <Link to='https://www.facebook.com/RATSystem1' target='_blank'>Facebook</Link>
            <Link to='/' target='_blank'>Youtube</Link>
            <Link to='https://www.tiktok.com/@ratsystem_iasi/' target='_blank'>TikTok</Link>
          </div>
        </div>
      </div>
      <section class='social-media'>
        <div class='social-media-wrap'>
          <div class='footer-logo'>
            <Link to='/' className='social-logo'>
              RatSystem
              <i class='fa fa-laptop' />
            </Link>
          </div>
          <small class='website-rights'>RatSystem © 2020</small>
          <div class='social-icons'>
            <Link
              class='social-icon-link facebook'
              to='https://www.facebook.com/RATSystem1'
              target='_blank'
              aria-label='Facebook'
            >
              <i class='fab fa-facebook-f' />
            </Link>
            <Link
              class='social-icon-link instagram'
              to='https://www.instagram.com/ratsystem_iasi/'
              target='_blank'
              aria-label='Instagram'
            >
              <i class='fab fa-instagram' />
            </Link>
            <Link
              class='social-icon-link youtube'
              to='/'
              target='_blank'
              aria-label='Youtube'
            >
              <i class='fab fa-youtube' />
            </Link>
            <Link
              class='social-icon-link twitter'
              to='https://www.tiktok.com/@ratsystem_iasi/'
              target='_blank'
              aria-label='TikTok'
            >
              <i class='fab fa-tiktok' />
            </Link>
           
          </div>
        </div>
      </section>
    </div>
  );
}

export default Footer;