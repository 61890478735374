import React from 'react';
import CarouselComponent from '../Carousel';
import Footer from '../Footer';
import '../../App.css';

const Gallery = () => {
  const carouselData = [
    {
    image: "/images/upgradeXFX/upgrade1.JPEG",
    caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade2.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade3.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade4.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade5.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade6.JPG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade7.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/upgradeXFX/upgrade8.JPEG",
      caption: "Upgrade GTX 1650 -> XFX AMD Radeon RX 6750 XT"
    },
    {
      image: "/images/asamblare1/asamblare1.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare2.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare3.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare4.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare5.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare6.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare7.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },
    {
      image: "/images/asamblare1/asamblare8.JPEG",
      caption: "Asamblare 16GB RAM + i7 3770 + GTX 1650"
    },


  ];

  return (
    <>
      <div className='gallery-container'>
        <div className='carousel'>
          <CarouselComponent data={carouselData}/>
        </div>
        <Footer/>
      </div>
      
      
     
      
      
    </>
      
    

    
  );
};

export default Gallery;