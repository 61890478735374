import React from 'react'
import '../../App.css';
import Footer from '../Footer';
import Cards from '../Cards';
function Servicii() {
  return (
    <>
    <Cards/>
    <Footer />

    </>

    
  )
}

export default Servicii;