import React from 'react';
import './Cards.css';
import CardItem from './CardItem';

function Cards() {
  return (
    <div className='cards'>
      <h1>RatSystem va poate oferi urmatoarele servicii</h1>
      <div className='cards__container'>
        <div className='cards__wrapper'>
          <ul className='cards__items'>
            <CardItem
              src='/images/img-9.JPEG'
              text='Oferim servicii de asamblare premium a componentelor dumneavoastră, totodată includem și serviciul de cable-management astfel încât sistemul sa arate cat mai aerisit!'
              label='Asamblare'
              path='/servicii'
            />
            <CardItem
              src='/images/error.JPEG'
              text='Nu știi de ce sistemul nu porneste ?Nu înțelegi de ce calculatorul sau laptop-ul scoate sunete ciudate? Oferim servicii de diagnosticare și revenim cu soluții'
              label='Diagnosticare'
              path='/servicii'
            />
          </ul>
          <ul className='cards__items'>
            <CardItem
              src='/images/img-3.JPG'
              text='Oferim servicii de curatare si de inlocuire a pastei termoconductoare, atat pc-urilor(CPU / GPU ), cat si a laptop-urilor.'
              label='Curatare'
              path='/servicii'
            />
            <CardItem
              src='/images/windows.jpg'
              text='Oferim servicii de instalare a sistemelor de operare, atat Windows, cat si Linux indiferent de versiunea aleasa'
              label='Instalare Sistem de Operare'
              path='/servicii'
            />
            <CardItem
              src='/images/upgrade.JPEG'
              text='Oferim servicii de upgrade a componentelor din echipamentele dumneavoastra'
              label='Upgrade'
              path='/servicii'
            />
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Cards;