import React from "react";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css"
import Home from './components/pages/Home'
import Gallery from "./components/pages/Gallery";
import Servicii from "./components/pages/Servicii";
function App() {
  return (
    <>
      <Router>
      <Navbar />
        <Routes>
          <Route path="/" exact Component={Home}/>
          <Route path="/servicii" exact Component={Servicii}/>
          <Route path="/despre-noi" exact Component={Home}/>
          <Route path="/galerie" exact Component={Gallery}/>
        </Routes>
      </Router>

    </>
      
    
  );
}

export default App;
